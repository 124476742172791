










import Vue from 'vue';
import { mapGetters } from 'vuex';
import OnlineUsersComponent from '@/views/dashboard/components/OnlineUsersComponent.vue';
import RegisteredUsers from '@/views/dashboard/components/RegisteredUsers.vue';

export default Vue.extend({
  name: 'Dashboard',
  components: {
    OnlineUsersComponent,
    RegisteredUsers,
  },
  computed: mapGetters({ getUserData: 'getUserData' }),
});
