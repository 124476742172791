





















import Vue from 'vue';
import { mapGetters } from 'vuex';
import DashboardRequest from '@/domain/dashboard/DashboardRequest';

export default Vue.extend({
  name: 'RegisteredUsers',
  data: () => ({ total: '0' }),
  mounted() {
    this.getData();
  },
  computed: mapGetters({ getUserData: 'getUserData' }),

  beforeDestroy() {
    clearTimeout();
  },
  methods: {
    async getData() {
      if (this.getUserData.role === 'admin') {
        try {
          const { data }: any = await DashboardRequest.getTotalUsers();
          this.total = String(data).padStart(2, '0');
        } finally {
          setTimeout(() => this.getData(), 10000);
        }
      }
    },
  },
});
